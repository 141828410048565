<template>
    <div>
        <Nav name="about"></Nav>
        <div class="contact">
            <b-container>
                <b-row>
                    <b-col l="4">
                        <section class="info">
                            <dl>
                                <dt v-text="name"></dt>
                                <dd>
                                    <p>电话：<a :href='toMobile' v-text="mobile"></a></p>
                                    <p>邮箱：<a :href="toMail" v-text="mail"></a></p>
                                    <p>地址：{{ address }}</p>
                                </dd>
                            </dl>
                        </section>
                    </b-col>
                    <b-col l="8">
                        <div id="map">
                            <amap :zoom="map.zoom" :center="map.center">
                                <amap-marker :position="map.position"></amap-marker>
                            </amap>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
    import Nav from '../components/Nav.vue'

    export default {
        name: 'Contact',
        components: {
            Nav
        },
        data () {
            return {
                name: '安徽自力网络科技有限公司',
                mobile: '177-6441-2196',
                mail: 'admin@ziliwangluo.com',
                address: '安徽省合肥市蜀山区蔚蓝商务港E座1616室',

                map: {
                    zoom: 16,
                    center: [117.234192, 31.8129],
                    position: [117.233900, 31.81287]
                }
            }
        },
        computed: {
            toMobile () {
                return 'tel:' + this.mobile;
            },
            toMail () {
                return 'mailto:' + this.mail;
            }
        }
    }
</script>

<style lang="less" scoped>
    .contact {
        background-color: #F8F8FA;
        padding-top: 20rem;
        height: 82rem;

        #map {
            width: 84rem;
            height: 42rem;
        }

        .info dl {
            padding-top: 11.5rem;

            dt {
                font-size: 2.8rem;
                margin-bottom: 2.2rem
            }
        }
    }
</style>
